<template>
    <a-modal title="Company's acknowledgment" visible :onCancel="handleCancel" okText="Generate Invoice" @ok="onSubmit">
        <div>
            <FormValidationWrap>
                <VerticalFormStyleWrap>
                    <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState"
                        :rules="rules" :layout="formState.layout">
                        <a-row :gutter="25">
                            <a-col :span="24">
                                <a-form-item label="Condition" name="saleGenerationCondition">
                                    <a-select v-model:value="formState.saleGenerationCondition" placeholder="Expense">
                                        <a-select-option :value="2">
                                            Change PO</a-select-option>
                                        <a-select-option :value="3">
                                            Partial Delivery</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>
                                <a-col :span="12" :xs="12" v-if="formState.saleGenerationCondition == 2">
                                    <a-form-item name="customerPoNumber" label="Customer PO number ">
                                        <a-input :disabled="readOnlyMode" v-model:value="formState.customerPoNumber" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="12" :xs="12" v-if="formState.saleGenerationCondition == 2">
                                    <a-form-item name="date" label="Date">
                                        <a-date-picker :disabled="readOnlyMode" v-model:value="formState.customerPoDate"
                                            :style="{ width: '100%' }" :format="dateFormat" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="24" :xs="24"><a-form-item ref="newAcknowledgmentFile"
                                        label="Acknowledgment File" name="newAcknowledgmentFile" v-if="formState.saleGenerationCondition == 2">
                                        <a-upload v-model:file-list="acknowledgmentFileList"
                                            name="newAcknowledgmentFile" :multiple="false"
                                            :customRequest="uploadAcknowledgmentFile" :style="{ width: '100%' }"
                                            :showUploadList="false">
                                            <a-button class="btn-outlined" size="large" :outlined="true"
                                                v-if="acknowledgmentFileList.length != 1">
                                                <upload-outlined></upload-outlined>
                                                <span>Select Files</span>
                                            </a-button>
                                        </a-upload>
                                        <div v-if="acknowledgmentFileList.length">
                                            <div v-for="file in acknowledgmentFileList" :key="file.uid"
                                                class="file-item">
                                                <span class="file-text" :title="file.fileName">{{ file.fileName
                                                    }}</span>
                                                <a @click="handleDownload(file)">
                                                    <sdFeatherIcons type="download" />
                                                </a>
                                                <a @click="removeFile(file)">
                                                    <sdFeatherIcons type="trash-2" />
                                                </a>
                                            </div>
                                        </div>
                                    </a-form-item>
                                </a-col>
                        </a-row>
                    </a-form>
                </VerticalFormStyleWrap>
            </FormValidationWrap>
        </div>

    </a-modal>
</template>
<script setup>
import { useStore } from "vuex";
import { reactive, ref, onMounted, defineEmits, defineProps } from "vue";
import { useFileDownload } from '@/composable/useFileDownload';
import {
    FileIdItemName, FileTypeEnum
} from '@/common/Enums';
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "@/view/forms/overview/Style";
import { DataService } from "@/config/dataService/dataService";
import { notification } from "ant-design-vue";
import { useRouter } from "vue-router";
const router = useRouter();

const { dispatch } = useStore();
const { uploadFile, downloadFile } = useFileDownload();
const props = defineProps(['saleId']);

/// Apiss data
const acknowledgmentFileList = ref([]);

////
const emit = defineEmits(['cancel'])
const rules = {
    customerPoNumber: [
        {
            required: true,
            message: "Po number is required",
            trigger: "change",
        },
    ],
    customerPoDate: [
        {
            type: "object",
            required: true,
            message: "Date is required",
            trigger: "change",
        },
    ],
};
const formRef = ref();
var formState = reactive({
    layout: "vertical",
    saleId: 0,
    saleGenerationCondition: "",
    customerPoNumber: null,
    customerPoDate: null,
    customerPoFileId: "",
});

onMounted(() => {
    formState.saleId = props.saleId;
});

const uploadAcknowledgmentFile = async (file) => {
    const fileForm = {
        file: file.file,
        itemType: FileIdItemName.SalePoAcknowledge,
        itemId: "",
        itemParentType: FileIdItemName.Sale,
        itemParentId: props.saleId,
        fileType: FileTypeEnum.SaleCustomerPoFile,
        name: file.file.name,
    }
    const response = await uploadFile(fileForm);
    const responseFile = response.data;
    const newfile = {
        id: responseFile.fileId,
        uid: responseFile.fileId,
        ItemId: responseFile.fileId,
        name: responseFile.filename,
        extension: responseFile.extension,
        fileName: responseFile.filename,
        fileType: responseFile.fileType,
        url: `${process.env.VUE_APP_API_ENDPOINT}api/File/download?Id=${responseFile.fileId}`,
        success: true,
        status: "done",
    };

    acknowledgmentFileList.value = [newfile];
    formState.customerPoFileId = newfile.id;
};
const onSubmit = async () => {
    if (formState.saleGenerationCondition == 2 && !acknowledgmentFileList.value.length) {
        notification.warning({
            message: "Please Add PO File",
        });
        return;
    }
    try {
        var response;
        response = await DataService.post(
            "/api/Sale/CreateInvoiceWithCondition",
            formState
        );

        if (response) {
            if (response.succeeded) {
                notification.success({
                    message: "Invoice Generated",
                });

                router.push("/sale/edit-invoice-order/" + response.data);
            }
        } else {
            notification.error({
                message: response.message,
            });
        }
    } catch (err) {
        notification.error({
            message: response.message,
        });
    }
};
const handleDownload = async (file) => {
    // Pass the file ID to initiate download
    await downloadFile(file);
};
const removeFile = (file) => {
    dispatch("removeFile", file);
    acknowledgmentFileList.value = [];
};

const handleCancel = () => {
    if (acknowledgmentFileList.value.length) {
        dispatch("removeFile", acknowledgmentFileList.value[0]);
    }
    emit('cancel');
}
</script>

<style scoped>
.file-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-item {
    margin-top: 4px;
    display: flex;
}

.file-item a,
.file-item span {
    margin-left: 8px;
    cursor: pointer;

    &:nth-child(2) {
        color: #1890ff !important;
    }

    &:last-child {
        color: red !important;
    }
}
</style>